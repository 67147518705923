import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    notification,
    Pagination,
    Checkbox,
    Image,
    Switch, // Step 1: Import Pagination
} from "antd";
import axios from "axios";
import { Link } from 'react-router-dom';
import { baseUrl } from "../../config";

const { Title } = Typography;

// table code start


function Users() {
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);

    useEffect(() => {
        getUserList();
    }, [currentPage, pageSize]); // Update data when page or page size changes

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "32%",
        },
        {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
        },
        {
            title: "Gender",
            dataIndex: "gender",
            key: "gender",
        },
        {
            title: "Document",
            dataIndex: "document",
            key: "document",
        },
        {
            title: "Is Verified",
            dataIndex: "isVerified",
            key: "isVerified",
        },
        {
            title: "STATUS",
            key: "status",
            dataIndex: "status",
        },
        {
            title: "Actions",
            key: "actions",
            dataIndex: "actions",
        },
    ];

    async function getUserList() {
        try {
            const response = await axios.get(`${baseUrl}/api/admin/list`, {
                params: {
                    page: currentPage,
                    limit: pageSize,
                },
            });
            if (response.status === 200) {
                setUserList(response.data.result);
                setTotalUsers(response.data.pagination.count);
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight',
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight',
            });
        }
    }

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    async function handleSwitchChange(userId) {
        try {
            const response = await axios.get(`${baseUrl}/api/user/profile-verified/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`, // Include access token in headers
                },
            });
            if (response.data.success) {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
                getUserList();
            } else {
                notification.info({
                    message: 'Info',
                    description: response.data.message,
                    placement: 'topRight'
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.info({
                message: 'Info',
                description: error.response?.data?.message,
                placement: 'topRight'
            });
        }
    };

    async function handleActivateUser(userId) {
        try {
            const response = await axios.put(`${baseUrl}/api/admin/activate-user/${userId}`, null, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: response.data.message,
                    placement: 'topRight',
                });
                getUserList(); // Refresh user list after activation
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message,
                    placement: 'topRight',
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.error({
                message: 'Error',
                description: error.response?.data?.message,
                placement: 'topRight',
            });
        }
    }

    async function handleDeactivateUser(userId, status) {
        try {
            const response = await axios.patch(`${baseUrl}/api/admin/status/${userId}?enabled=${status}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
            });
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: response.data.message,
                    placement: 'topRight',
                });
                getUserList(); // Refresh user list after deactivation
            } else {
                notification.error({
                    message: 'Error',
                    description: response.data.message,
                    placement: 'topRight',
                });
            }
        } catch (error) {
            console.error("API error:", error);
            notification.error({
                message: 'Error',
                description: error.response?.data?.message,
                placement: 'topRight',
            });
        }
    }


    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Users"
                            extra={
                                <>

                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={userList.map((user, index) => ({
                                        key: index.toString(),
                                        name: (
                                            <Avatar.Group>
                                                <Avatar
                                                    className="shape-avatar"
                                                    shape="square"
                                                    size={40}
                                                    src={baseUrl + '/' + user.photo}
                                                ></Avatar>
                                                <Link to={`/profile/${user._id}`}>
                                                    <div className="avatar-info">
                                                        <Title level={5}>{user.name}</Title>
                                                        <p>{user.email}</p>
                                                    </div>
                                                </Link>

                                            </Avatar.Group>
                                        ),
                                        phoneNumber: (
                                            <div className="author-info">
                                                <p>{user.phoneNumber}</p>
                                            </div>
                                        ),
                                        gender: (
                                            <div className="author-info">
                                                <p>{user.gender}</p>
                                            </div>
                                        ),
                                        document: (
                                            user.document ?
                                                <Image
                                                    width={50}
                                                    src={baseUrl + '/' + user.document}
                                                />
                                                : "-"
                                        ),
                                        isVerified: (
                                            <Switch checked={user.isVerified} onChange={() => handleSwitchChange(user._id)} />
                                        ),
                                        status: (
                                            <span className={user.enabled ? 'text-success' : 'text-danger'}>
                                                {user.enabled ? 'Active' : 'Inactive'}
                                            </span>
                                        ),
                                        actions: (
                                            <div>
                                                {user.enabled ? (
                                                    <Button type="danger" onClick={() => handleDeactivateUser(user._id, false)}>
                                                        Deactivate
                                                    </Button>
                                                ) : (
                                                    <Button type="primary" onClick={() => handleDeactivateUser(user._id, true)}>
                                                        Activate
                                                    </Button>
                                                )}
                                            </div>
                                        )
                                    }))}
                                    pagination={{ // Step 3: Add pagination settings
                                        current: currentPage,
                                        pageSize: pageSize,
                                        total: totalUsers,
                                        onChange: handlePageChange,
                                    }}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Users;
